<template lang="pug">
  .currency(:class="[{'--reversed': isReversed}, `--${type}`, {'--hide': isBalanceHide}]")
    .currency__text(v-if="isReversed")
      slot
    .currency__icon
      InlineSvg.currency__ticket(
        v-if="type === 'tickets'"
        :height="size"
        :width="size"
        :src="require('~/assets/img/icons/trophy.svg')"
      )
      InlineSvg.currency__star(
        v-else-if="type === 'stars'"
        :height="size"
        :width="size"
        :src="require('~/assets/img/star.svg')"
      )
      InlineSvg.currency__euro(
        v-else-if="type === 'money' && rub"
        :height="size"
        :width="size"
        src="/currencies/rub.svg"
      )
      InlineSvg.currency__euro(
        v-else-if="type === 'money' && real"
        :height="size"
        :width="size"
        src="/currencies/eur.svg"
      )
      InlineSvg.currency__g(
        v-else-if="type === 'money'"
        :height="size"
        :width="size"
        src="/currencies/v.svg"
      )
      InlineSvg.currency__coins(v-else :height="size" :width="size" :src="require('~/assets/img/icons/coins-new.svg')")
    .currency__text(v-if="!isReversed")
      slot

</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'Currency',

  props: {
    type: {
      type: String,
      default: 'coins'
    },

    size: {
      type: String,
      default: '24'
    },

    isReversed: {
      type: Boolean,
      default: false
    },

    real: {
      type: Boolean,
      default: false
    },

    rub: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState('user', ['user', 'isBalanceHide'])
  },

  beforeMount () {
    this.toggleUserBalanceVisibility(
      window.localStorage.getItem('isBalanceHide')
    )
  },

  methods: {
    ...mapMutations('user', ['toggleUserBalanceVisibility'])
  }
}
</script>

<style lang="scss">
.currency {
  display: inline-flex;
  align-items: center;

  &.--hide {
    .currency__text {
      background: rgb(255 255 255 / 30%);
      filter: blur(6px);
      border-radius: 4px;
    }
  }

  &.--tickets {
    svg {
      margin-right: 2px;
    }
  }

  &.--reversed {
    svg {
      margin-left: 4px;
      margin-right: 0;
    }
  }

  &__icon {
    height: auto;
    margin-right: 2px;
  }

  svg {
    display: block;
    height: 100%;
  }

  &__coins path {
    fill: $amber50;
  }

  &__star path {
    fill: $amber50;
  }

  @media (max-width: $breakpointMobile) {
    &__icon {
      margin-right: 0;
    }
  }
}
</style>
